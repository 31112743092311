/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"); */

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@500&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body {
  font-family: "Cairo", sans-serif !important;
}

option {
  color: black;
}

* {
  letter-spacing: 0.3px;
  outline: none;
}

::-webkit-scrollbar {
  height: 4px !important;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: #b9b9b9;
  border-radius: 20px;
}

.css-70fard,
.apexcharts-toolbar {
  z-index: 0 !important;
}

.fc-bg-event {
  opacity: 1 !important;
}

.p0 {
  margin-top: 0;
}

/* TABLE  */
.table-container {
  min-height: 23vh;
  max-height: 23vh;
  overflow-y: auto;
}

.table-container thead {
  border-bottom: 1px solid #a5805b;
  box-shadow: -2px 0px 4px -2px #5c5d5efa;
  position: sticky;
  top: 0;
  height: 25px;
  background: #ffffff;
}

.table-container-property {
  min-height: auto;
  max-height: 68vh;
  overflow-y: auto;
}

.css-efk3t2 {
  max-height: 15rem !important;
  overflow-y: scroll !important;
}

.table-container-property thead {
  position: sticky;
  top: 0;
  height: 25px;
  background: #ffffff;
  border-bottom: 1px solid #a5805b;
  box-shadow: -2px 0px 4px -2px #5c5d5efa;
}

.table-fix-container {
  min-height: 63vh;
  max-height: 63vh;
  overflow-y: auto;
}

.small-table-fix-container {
  min-height: 15vh;
  max-height: 63vh;
  overflow-y: auto;
}

.table-fix-container thead {
  border-bottom: 1px solid #a5805b;
  box-shadow: -2px 0px 4px -2px #5c5d5efa;
  position: sticky;
  top: 0;
  height: 25px;
  background: #ffffff;
}

.importTable {
  min-height: auto;
  max-height: 82vh;
  overflow-y: auto;
}

.tableData {
  padding: 10px !important;
}

.datePickerBorder {
  border: 1px solid #e2e8f0;
  padding: 0 5px;
  border-radius: 5px;
  margin: 0 10px !important;
}

.imageCard {
  position: relative;
  overflow: hidden;
  margin-right: 12px;
}

.imageContent {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000096;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.imageCard:hover .imageContent {
  opacity: 1;
}

.EditDelete {
  display: none;
}

.CustomFieldName {
  border-bottom: 1px solid rgba(128, 128, 128, 0.705);
}

.CustomFieldName:hover .EditDelete {
  display: block;
}

.pending {
  background-color: #fbf4dd !important;
  color: #a37f08 !important;
  border: 1px solid #a37f08 !important;
}

.onHold {
  background-color: #ffeeeb !important;
  color: #DB5436 !important;
  border: 1px solid #DB5436 !important;
}

.completed {
  background-color: #eaf9e6 !important;
  color: #4d8f3a !important;
  border: 1px solid #4d8f3a !important;
}

.toDo {
  background-color: #ebf5ff !important;
  color: #1f7eeb !important;
  border: 1px solid #1f7eeb !important;
}

.inProgress {
  background-color: #eee7fc !important;
  color: #7038db !important;
  border: 1px solid #7038db !important;
}

.chakra-checkbox[disabled] .chakra-checkbox__control {
  background-color: #dcdee0 !important;
  border: 2px solid #d1d1d1;
}


.notifications-container {
  position: relative;
}

.notifications-icon {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.notifications-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 12px; /* Small size */
  height: 12px; /* Small size */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px; /* Adjust font size for small circle */
  font-weight: bold;
}

.notifications-container {
  position: relative; /* Ensure dropdown positioning is relative to this container */
}

.notifications-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-width: 1000px !important; /* Force width */
  overflow-y: auto; /* Allows vertical scrolling if necessary */
  max-height: 400px; /* Optional: Limits the height of the dropdown */
  border-radius: 8px; /* Small rounded edges */
}

.notification-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px; /* Increased padding for a more spacious card */
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9;
  margin: 5px 0; /* Space between cards */
  border-radius: 8px; /* Rounded corners */
  width: 100%; /* Make the card take full width of the dropdown */
}

.notification-content {
  flex: 1; /* Takes up remaining space */
  padding-right: 10px; /* Space between content and button */
}

.read-button {
  background-color: #007bff; /* Blue color */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
}

.read-button:hover {
  background-color: #0056b3; /* Darker blue for hover effect */
}

.no-notifications {
  padding: 10px;
  color: #888;
  text-align: center; /* Center text */
}

.notification-spacing {
  margin-right: 10px; /* Adjust the value to create space */
}

.custom-font {
  font-family: 'Cairo', sans-serif !important;
}


.floating-whatsapp-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.floating-main-button {
  background-color: #25D366 !important;
  color: white !important;
  border: none !important;
  border-radius: 50px !important;
  padding: 10px 20px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  min-width: 120px;
}

.floating-main-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.floating-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  animation: slideIn 0.3s ease-out;
}

.floating-option-button {
  background-color: #25D366 !important;
  color: white !important;
  border: none !important;
  border-radius: 50px !important;
  padding: 10px 20px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
}

.floating-option-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.whatsapp-send-button {
  background-color: #25D366 !important;
  color: white !important;
  border: none !important;
  margin-left: 6px !important;
}

@keyframes slideIn {
  from {
      transform: translateY(20px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

/* Optional: Add backdrop when options are expanded */
.floating-whatsapp-container::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: -1;
}

.floating-whatsapp-container.expanded::before {
  opacity: 1;
  visibility: visible;
}
.tenant-management .main-card {
  background: white;
}

.tenant-management .ant-table-thead > tr > th {
  background: #fafafa;
  font-weight: 600;
}

.tenant-management .ant-table-tbody > tr:hover > td {
  background: #f0f7ff;
}

.tenant-management .ant-form-item-label > label {
  font-weight: 500;
}

.tenant-management .ant-input,
.tenant-management .ant-picker {
  border-radius: 6px;
}

.tenant-management .ant-table {
  border-radius: 8px;
  overflow: hidden;
}

.tenant-management .ant-modal-content {
  border-radius: 8px;
}

.tenant-management .ant-btn {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}